.wrapper{
    padding: 0px 0 0 0;
    .titlebox {
      background: url("https://t3.ftcdn.net/jpg/05/08/90/66/360_F_508906681_kP2eq4bGMKChxPYPpurOFb9GUz5IZ4GH.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size:contain;
      height: 400px;
      margin-top: 60px;
      mix-blend-mode: darken;
      @media (max-width:768px) {
        margin-top: 30px;
        
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 200px;
      }
  
      .title {
        padding: 20px 20px;
  
        h1 {
          color: #fff;
        }
        p {
          font-weight: bolder;
          color: #fff;
          font-size: 1.2rem;
        }
  
        hr {
          display: block;
          background-color: white;
          height: 0.25rem;
          width: 6.25rem;
          border: none;
          margin: 1.125rem 0 0.75rem 0;
        }
      }
    }
    .main {
      padding: 3px 20px;
      @media (max-width: 768px) {
        width: 100%;
        padding: 0%;
      }
  
      .mainhero {
        display: flex;
        width: 100%;
        gap: 80px;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column-reverse;
          gap: 0%;
          width: 100%;
        }
        .mainHerotext {
          height: 500px;
  
          width: 55%;
          display: grid;
          place-content: center;
          @media (max-width: 768px) {
            display: block;
            width: 100%;
            height: auto;
          }
          h1 {
            font-family: "Cinzel Decorative", cursive;
            color: rgb(62, 7, 92);
            font-weight: 500;
            @media (max-width: 768px) {
              font-size: 1.2rem;
              width: auto;
              text-align: center;
             
            }
          }
          p {
            font-family: "Cinzel", serif;
            font-weight: 700;
            font-size: 18px;
            width: 550px;
            color: rgb(4, 66, 4);
            @media (max-width: 768px) {
              font-size: 0.8rem;
              width: auto;
              padding: 0 60px;
              text-align: center;
            }
          }
        }
        .mainHerogif {
          width: 40%;
          @media (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
          .webGIF {
            width: 500px;
            height: 500px;
            @media (max-width: 768px) {
              width: 600px;
              height: 600px;
            }
          }
        }
      }
    }
    .container{
        .intro{
            display: flex;
            width: 100%;
            gap: 5px;
            @media (max-width:768px) {
                flex-direction: column;
                
            }
            .introimg{
                width: 50%;
                @media (max-width:768px) {
                    width: 100%;
                    
                }
             img{
                width: 100%;
             height: 400px;
             }
            }
            .introtext{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: center;
                padding: 0 2rem;
                @media (max-width:768px) {
                    width: 100%;
                    
                }
                p{
                    font-size: 1.3rem;
                    font-weight: 800;
                }
            }
        }
       
       .typecontainer{
        padding:  10px 20px;
        display: flex;
        @media (max-width:768px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
        }
        .types{
            display: flex;
           
            flex-direction: column;
            ul{
                li{
                list-style:none;
                i{
                    font-size: 1.5rem;
                    color: green;
                }
              
                }
            }
         
        }
        .img{
            width: 50%;
            @media (max-width:768px) {
                width: 100%;
                
            }
            img{
                width: 100%;
            }
        }

       }
      
    }
}