*{

font-family: 'Poppins', sans-serif;
scroll-behavior: smooth;

}
body {
  box-sizing: border-box;
 

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


