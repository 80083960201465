.hero-wrapper {

  margin-top: 9rem;


  .hero-btns {
    display: flex;
    height: 100%; /* Added to make the container occupy full height */
    position: absolute; /* Added to position the container */
    top: 0; /* Added to position the container at the top */
    left: 0; /* Added to position the container at the left */
    right: 0; /* Added to position the container at the right */
    margin-top: auto;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .left-btn,
  .right-btn {
    padding: 10px 20px;
    button {
      padding: 0.1em 0.25em;
      width: 13em;
      height: 4.2em;
      background-color: #212121;
      border: 0.08em solid #fff;
      border-radius: 0.3em;
      font-size: 12px;
    }
    
    button span {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0.4em;
      width: 8.25em;
      height: 2.5em;
      background-color: #212121;
      border-radius: 0.2em;
      font-size: 1.5em;
      color: #fff;
      border: 0.08em solid #fff;
      box-shadow: 0 0.4em 0.1em 0.019em #fff;
    }
    
    button span:hover {
      transition: all 0.5s;
      transform: translate(0, 0.4em);
      box-shadow: 0 0 0 0 #fff;
    }
    
    button span:not(hover) {
      transition: all 1s;
    }
    
    
    
  }

  .hero-title {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 20rem;
      font-weight: 900;
      animation: changeBackground 5s infinite linear;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-family: "Monoton", cursive;
      @media (max-width: 768px) {
        font-size: 9.5rem;
      }
    }
  }

  .hero-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 0 60px;
      width: 100%;
      text-align: center;
      h1 {
        font-size: 1.1rem;
      }
    }

    h1 {
    }

    p {
    }
  }
}

@keyframes changeBackground {
  0% {
    background-image: url("../../Assets/Default1.jpg");
  }
  20% {
    background-image: url("../../Assets/Default3.jpg");
  }
  40% {
    background-image: url("../../Assets/Default4.jpg");
  }
  60% {
    background-image: url("../../Assets/Default5.jpg");
  }
  80% {
    background-image: url("../../Assets/Default6.jpg");
  }
  100% {
    background-image: url("../../Assets/Default1.jpg");
  }
}
