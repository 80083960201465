.wrapper {
  padding: 20px 120px;
  @media (max-width: 768px) {
    padding: 40px 20px;
  }
  .titleBox {
    display: flex;
    @media (max-width: 768px) {
      display: block;
    }
    .title {
      width: 100%;
      margin-bottom: 80px;
      span {
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 1rem;
        color: #717171;
      }
      h1 {
        text-transform: capitalize;
        letter-spacing: 0.8px;
        font-family: "Roboto", sans-serif;
        font-weight: 900;
        font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
        background-color: #000000;
        background: linear-gradient(-45deg, grey 0%, black 100%);
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        @media (max-width: 768px) {
          font-size: clamp(1.8375rem, 1.25rem + 0.35vw, 1rem);
        }
      }
      hr {
        display: block;
        background: #000000;
        height: 0.25rem;
        width: 6.25rem;
        border: none;
        margin: 1.125rem 0 1.875rem 0;
      }
    }
    .titlePara {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 768px) {
        width: 100%;
      }
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
      }

      button {
        --primary-color: #111;
        --hovered-color: #c84747;
        position: relative;
        display: flex;
        font-weight: 600;
        font-size: 20px;
        gap: 0.5rem;
        align-items: center;
        align-self: start;
      }

      button p {
        margin: 0;
        position: relative;
        font-size: 20px;
        color: var(--primary-color);
      }

      button::after {
        position: absolute;
        content: "";
        width: 0;
        left: 0;
        bottom: -7px;
        background: var(--hovered-color);
        height: 3px;
        transition: 0.5s ease-out;
      }

      button p::before {
        position: absolute;
        /*   box-sizing: border-box; */
        content: "Get In Touch";
        width: 0%;
        inset: 0;
        color: var(--hovered-color);
        overflow: hidden;
        transition: 0.3s ease-out;
      }

      button:hover::after {
        width: 100%;
      }

      button:hover p::before {
        width: 100%;
      }

      button:hover svg {
        transform: translateX(4px);
        color: var(--hovered-color);
      }

      button svg {
        color: var(--primary-color);
        transition: 0.3s;
        position: relative;
        width: 15px;
        transition-delay: 0.2s;
      }
    }
  }
  .cards {
    display: flex;
    justify-content: space-around;
    gap: 100px;
    position: relative;
    margin: 80px 0;
    @media (max-width: 768px) {
      display: flex;
     flex-wrap: wrap;
      margin: 0 auto;
      align-items: center;
      padding: 80px 50px;
      gap:20px;
      #card4{
        margin-bottom: 0%;
      }
    }

    .card {
      height: 300px;
      width: 230px;
      background-color: #e8e8e8;
      border-radius: 20px;
      box-shadow: 10px 50px 55px -50px #000000;
      position: relative;
      overflow: hidden;
      transform: scale(1.3);
      @media (max-width:768px) {
        width: 100%;
        height: 350px;
        margin-bottom: 10rem;
        
      }

      &::before {
        content: "";
        position: absolute;
        height: 150px;
        width: 150px;
        background: linear-gradient(120deg, #0034c4, #828af3);
        right: 0;
        border-radius: 0 0 0 158px;
        box-shadow: 0 5px 3px #000;
        transition: 0.5s;
      }
      &:hover::before {
        height: 100%;
        width: 100%;
      }
      .prp {
        position: absolute;
        padding: 20px;
        text-align: center;
        top: 100px;
        transition: 0.5s;
        &:hover {
          color: #e8e8e8;
        }
        h1 {
          font-size: 22px;
        }
        p {
          font-size: 12px;
          line-height: 20px;
        }
      }

      img {
        position: absolute;
        top: 25px;
        right: 10px;
        width: 78px;
      }
      &:hover {
        color: #e8e8e8;
      }
    }
    #card2 {
      margin-top: 6rem;
      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
    #card4 {
      margin-top: 6rem;
      @media (max-width: 768px) {
        margin-top: 0%;
      }
    }
  }
}

.card:nth-child(2).card::before {
  background: linear-gradient(120deg, #c84747, yellowgreen);
}
.card:nth-child(3).card::before {
  background: linear-gradient(120deg, #4eafdc, rgb(215, 54, 170));
}
.card:nth-child(4).card::before {
  background: linear-gradient(120deg, #df1e1e, rgb(31, 143, 198));
}
