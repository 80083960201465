.footer {

  
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 20px;
  background-color: black;
  color: #e8e8e8;
border-bottom: solid 1px #e8e8e8;
  font-weight: 500;


  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  &__col1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img{
      width: 200px;
      mix-blend-mode:hard-light;
    }
  }

  &__logo {
    max-width: 100%;
    height: auto;
  }

  &__text {
    margin-top: 10px;
  }

  &__col2 {
    display: flex;
    flex-direction: column;
    margin:0 auto;
 align-items: center;   
 
    li {
      list-style: none;
      text-align: left;
      align-items: start;
      line-height: 2rem;
      a{
        text-decoration: none;
        color: #e8e8e8;
      }
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__address {
    margin: 0;
  }

  &__col3 {
    display: flex;
    flex-direction: column;
    margin:0 auto;
  
    
  }

  &__contacts {
    list-style: none;
    padding: 0;
    margin: 0;
    
    li{
      line-height:2rem;
    margin-bottom: 1.5rem;  
    }
    span{
      font-size: 2rem;
    }
  }

  &__contact-number {
    display: block;
    font-weight: bold;
  }

  &__contact-location {
    display: block;
    margin-top: 5px;
  }

  &__col4 {
    display: flex;
    align-items: center;
    @media (max-width:768px) {
      display: flex;
      flex-wrap: wrap;
      .card{
        flex-basis: 100%;
      }
    }

    .card {
      width: 100%;
      height: auto;
      background-color: rgb(238, 238, 238);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 25px;
      gap: 20px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
      border-radius: 10px;
    }
    
    /* for all social containers*/
    .socialContainer {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: rgb(44, 44, 44);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition-duration: .3s;
    }
    /* instagram*/
    .containerOne:hover {
      background-color: #d62976;
      transition-duration: .3s;
    }
    /* twitter*/
    .containerTwo:hover {
      background-color: #00acee;
      transition-duration: .3s;
    }
    /* linkdin*/
    .containerThree:hover {
      background-color: #0072b1;
      transition-duration: .3s;
    }
    /* Whatsapp*/
    .containerFour:hover {
      background-color: #128C7E;
      transition-duration: .3s;
    }
    
    .socialContainer:active {
      transform: scale(0.9);
      transition-duration: .3s;
    }
    
    .socialSvg {
      width: 17px;
    }
    
    .socialSvg path {
      fill: rgb(255, 255, 255);
    }
    
    .socialContainer:hover .socialSvg {
      animation: slide-in-top 0.3s both;
    }
    
    @keyframes slide-in-top {
      0% {
        transform: translateY(-50px);
        opacity: 0;
      }
    
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    
    
   
  }

 
}
.credit {
  background-color: black;
  color: white;
  margin: 0 auto;
  text-align: center;
  span{
    
    font-size: 2rem;
  }
}
