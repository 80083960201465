.wrapper {
  padding: 60px 0px;
  .container {
    position: relative;
    .titlebox {
      .title {
        padding: 20px 20px;
        h1 {
          color: #fff;
        }
        p {
          font-weight: bolder;
          color: #fff;
         font-size: 1.2rem;
          
        }

        hr {
          display: block;
          background-color: white;
          height: 0.25rem;
          width: 6.25rem;
          border: none;
          margin: 1.125rem 0 1.875rem 0;
        }
      }

      background: url("https://img.freepik.com/free-photo/abstract-business-people-city-buildings_53876-139657.jpg?w=1060&t=st=1688296756~exp=1688297356~hmac=860aefa914530980137438e345c1c7ba4823baac82b70bb01b2c74dd7f64aba4");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .content{
        padding: 0 120px;
        margin-bottom: 15rem;
        @media (max-width:768px) {
          display: flex;
          flex-direction: column;
          padding: 0 30px;

          
        }
     
        .whoweare{
            position: absolute;
            width: 550px;
            top: 50%;
            @media (max-width:768px) {
          display: grid;
          grid-template-columns:auto;
          position: relative;
          width: 100%;
              
            }

          h1{
            color: #00ac8e;
          }
            hr {
                
                display: block;
                background-color: black;
                height: 0.25rem;
                width: 6.25rem;
                border: none;
                margin: 1.125rem 0 0.85 0;
              }
      
             

        }
        .whatwedo{
            position: absolute;
            width: 550px;
            left: 60%;
            top: 88%;
            @media (max-width:768px) {
              margin-top: 3rem;
              display: grid;
              grid-template-columns:auto;
              position: relative;
              width: 100%;
              left: 0%;
            }
          h1{
            color: #00ac8e;

          }
            hr {
                
                display: block;
                background-color: black;
                height: 0.25rem;
                width: 6.25rem;
                border: none;
                margin: 1.125rem 0 1.875rem 0;
              }
      

        }
    }
  }
}
