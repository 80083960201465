.wrapper {
  padding: 80px 0 0 0;
  .titlebox {
    background: url("https://img.freepik.com/free-vector/combination-circuit-head-shape-artificial-intelligence-moral-electronic-world-illustration_456031-123.jpg?w=1380&t=st=1688392906~exp=1688393506~hmac=1c88850c7f34e6c02bbfda9ed75803ad86ea820a0ae1050b3ddae6c3d8fb4d6a");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .title {
      padding: 20px 20px;

      h1 {
        color: #fff;
      }
      p {
        font-weight: bolder;
        color: #fff;
        font-size: 1.2rem;
      }

      hr {
        display: block;
        background-color: white;
        height: 0.25rem;
        width: 6.25rem;
        border: none;
        margin: 1.125rem 0 0.75rem 0;
      }
    }
  }
  .main {
    padding: 3px 20px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0%;
    }

    .mainhero {
      display: flex;
      width: 100%;
      gap: 80px;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 0%;
        width: 100%;
      }
      .mainHerotext {
        height: 500px;

        width: 55%;
        display: grid;
        place-content: center;
        @media (max-width: 768px) {
          display: block;
          width: 100%;
        }
        h1 {
          font-family: "Cinzel Decorative", cursive;
          color: rgb(62, 7, 92);
          font-weight: 500;
          @media (max-width: 768px) {
            font-size: 1.2rem;
            width: auto;
            text-align: center;
          }
        }
        p {
          font-family: "Cinzel", serif;
          font-weight: 700;
          font-size: 18px;
          width: 550px;
          color: rgb(4, 66, 4);
          @media (max-width: 768px) {
            font-size: 0.8rem;
            width: auto;
            padding: 0 60px;
            text-align: center;
          }
        }
      }
      .mainHerogif {
        width: 40%;
        @media (max-width: 768px) {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
        .webGIF {
          width: 500px;
          height: 500px;
          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .webtypebox {
    padding: 20px 40px;
    @media (max-width: 768px) {
      margin-top: -16rem;
      padding: 0%;
    }

    .webTypeTitle {
      margin-bottom: 3rem;
      text-align: center;

      h1 {
        font-family: "Cinzel Decorative", cursive;
        color: rgb(62, 7, 92);
        font-size: 3rem;
        @media (max-width: 768px) {
          font-size: 1.5rem;
        }
        span {
          font-family: "Cinzel Decorative", cursive;
          color: rgb(4, 66, 4);
        }
      }
    }
    .webTypeCards {
      @media (max-width: 768px) {
        width: 100vw;
        padding: 0%;
        margin: 0%;
      }
      .webTypeCard {
        display: flex;
        justify-content: center;
        gap: 4rem;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0%;
          width: 100%;
          height: auto;
        }

        .card {
          padding: 20px;
          width: 330px;
          min-height: 370px;
          border-radius: 20px;
          background: #f6f0f0;
          box-shadow: 5px 5px 6px #cccbcb, -5px -5px 6px #d2d2d2;
          transition: 0.4s;
          @media (max-width: 768px) {
            margin-bottom: 2rem;
          }
        }

        .card:hover {
          translate: 0px -10px;
          @media (max-width: 768px) {
            translate: 0;
          }
        }

        .cardtitle {
          font-size: 18px;
          font-weight: 600;
          color: rgb(62, 7, 92);
          margin: 15px 0 0 10px;
        }

        .cardimage {
          min-height: 170px;
          background-color: #c9c9c9;
          border-radius: 15px;
          box-shadow: inset 8px 8px 10px #c3c3c3, inset -8px -8px 10px #cfcfcf;
          img {
            width: 100%;
            height: 170px;
            border-radius: 15px;
          }
        }

        .cardbody {
          margin: 13px 0 0 10px;
          color: rgb(31, 31, 31);
          font-size: 15px;
        }
      }
    }
  }

  .specsBox {
    padding: 50px 0;
    @media (max-width: 768px) {
      padding: 0;
      margin: 0%;
      width: 100vw;
      .specsMain {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .specsrow {
        display: flex;
        flex-direction: column;
      }
    }

    .specsMain {
      .specsrow {
        display: flex;
        width: 100%;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .rowimg {
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
          }
          img {
            width: 100%;
            height: 759.6px;
            @media (max-width: 768px) {
              height: auto;
              width: 100%;
            }
          }
        }
        .rowinfo {
          width: 50%;
          display: grid;
          place-content: center;
          padding: 0 20px 0 20px;
          @media (max-width: 768px) {
            height: auto;
            width: 100%;
            padding: 0%;
          }
          h1 {
            font-family: "Cinzel", serif;
            font-size: 3rem;
            @media (max-width: 768px) {
              font-size: 1.1rem;
              text-align: center;
            }
          }
          p {
            padding-right: 3rem;
            @media (max-width: 768px) {
              padding-right: 0%;
              text-align: center;
              font-size: 0.8;
            }
          }
        }
      }
      #row2,
      #row4 {
        display: flex;
        flex-direction: row-reverse;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
      }
      #row3 {
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }
        .rowimg {
          iframe {
            background-color: aqua;
            width: 759.6px;
            height: 759.6px;
            @media (max-width: 768px) {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      #row4 {
        .rowimg {
          iframe {
            width: 759.6px;
            height: 759.6px;
            @media (max-width: 768px) {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
