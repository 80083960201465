.wrapper {
  padding: 20px 180px;
  @media (max-width: 768px) {
    padding: 20px 20px;
    width: 100%;
  }
  .titleBox {
    text-align: center;
    @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    h1 {
      span {
        color: #ff9100;
      }
    }
    p {
      max-width: 650px;
      margin: 0 auto;
    }
  }

  .contactBox {
    display: flex;
    margin-top: 5rem;
    gap: 50px;
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
    }

    .contactInfo {
      width: 40%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .contactInfotitle {
      h3 {
        color: #ff9100;
      }
      p {
        color: rgb(103, 102, 102);
      }
      @media (max-width: 768px) {
        display: none;
      }
    }
    .contactInfodetails {
      @media (max-width: 768px) {
        display: flex;

        text-align: center;
        justify-content: space-around;
      }
      .info {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        align-items: center;
        @media (max-width: 768px) {
          display: flex;
          gap: 0;
          justify-content: space-around;
        }

        i {
          font-size: 4rem;
          color: linear-gradient(to bottom, #1f1f1f 0%, #777879 100%);
          @media (max-width: 768px) {
            font-size: 2.3rem;
          }
        }
        h4 {
          font-size: 1.2rem;
          span {
            font-size: 1rem;
            color: rgb(103, 102, 102);
          }
          @media (max-width: 768px) {
            font-size: 1rem;
            span {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
    .contactForm {
      @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        width: auto;
      }

      input {
        padding: 20px;
        width: 300px;
        height: 45px;
        border-radius: 10px;
        border: none;
        background-color: #e8e8e8;

        &:focus {
          border: none;
          outline: none;
          border: 4px solid #ff9100;
        }
      }
      .formrow3{
        @media (max-width:768px) {
        
          display: flex;
          flex-direction: column;
          display: none;
         
          textarea{
    

          }
          
        }
        
      textarea {
        height: 200px;
        width: 630px;
        border-radius: 10px;
        border: none;
        background-color: #e8e8e8;
        padding: 20px;

        &:focus {
          border: none;
          outline: none;
          border: 4px solid #ff9100;
        }
      }

      }
      
      .formrow1,
      .formrow2 {
        display: flex;
        gap: 30px;
        margin-bottom: 1.5rem;
        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
          width: 100%;
          input {
            width: 100%;
          }
        }

        
      
      }
      //button
      button {
        font-family: inherit;
        font-size: 18px;
        background: linear-gradient(to bottom, #1f1f1f 0%, #777879 100%);
        color: white;
        padding: 0.8em 1.2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 25px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        width: 100%;
      }

      button:hover {
        transform: translateY(-3px);
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
      }

      button:active {
        transform: scale(0.95);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      }

      span {
        display: block;
        margin-left: 0.4em;
        transition: all 0.3s;
      }

      svg {
        width: 18px;
        height: 18px;
        fill: white;
        transition: all 0.3s;
      }

      .svgwrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.2);
        margin-right: 0.5em;
        transition: all 0.3s;
      }

      button:hover,
      .svgwrapper {
        background-color: rgba(255, 255, 255, 0.5);
      }

      button:hover svg {
        transform: rotate(45deg);
      }
    }
  }
}
