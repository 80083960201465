.nav-main-container .nav-link {
    margin-right: 20px; 
    border-bottom: none;
    transition: border-bottom 0.3s ease-in-out;
 
  
  }
  

  .nav-main-container{
   
    a{
      text-decoration: none;
      color: black;
    }
    .boot-nav{
      background-color: rgb(243, 241, 234);
    }
  }

  .logo-main-nav{
    img{
      width: 350px;
      mix-blend-mode: darken;
      @media (max-width:768px) {
        width: 250px;
        
      }
    }
  }