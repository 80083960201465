.serviceIntrowrapper {
  padding: 20px 120px;
  @media (max-width: 768px) {
    padding: 0%;
    margin-top: 4rem;
  }

  .serviceIntrotitle {
    span {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 1rem;
      color: #717171;
    }
    h1 {
      text-transform: capitalize;
      letter-spacing: 0.8px;
      font-family: "Roboto", sans-serif;
      font-weight: 900;
      font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
      background-color: #000000;
      background: linear-gradient(-45deg, grey 0%, black 100%);
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      @media (max-width: 768px) {
        font-size: clamp(2.4375rem, 2.25rem + 0.35vw, 2rem);
      }
    }
    hr {
      display: block;
      background: #000000;
      height: 0.25rem;
      width: 6.25rem;
      border: none;
      margin: 1.125rem 0 1.875rem 0;
    }
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 25px 10px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      place-content: center;
      place-items: center;
    }

    .card {
      position: relative;
      width: 350px;
      height: 300px;
      color: #2e2d31;
      cursor: pointer;
      @media (max-width: 768px) {
        width: 100%;
        height: 300px;
        margin-bottom: 3.5rem;
      }

      overflow: hidden;
      border-radius: 20px;
      .cta {
        border: none;
        background: none;
      }

      .cta span {
        padding-bottom: 7px;
        letter-spacing: 4px;
        font-size: 12px;
        padding-right: 15px;
        cursor: pointer;
      }

      .cta svg {
        transform: translateX(-8px);
        transition: all 0.3s ease;
      }

      .cta:hover svg {
        transform: translateX(0);
      }

      .cta:active svg {
        transform: scale(0.9);
      }

      .hoverunderlineanimation {
        position: relative;
        color: black;
        padding-bottom: 20px;
        a {
          text-decoration: none;
          color: #000000;
        }
      }

      .hoverunderlineanimation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #000000;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      .cta:hover .hoverunderlineanimation:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }

    .temporarytext {
      font-weight: bold;
      font-size: 24px;
      padding: 6px 12px;
      color: #f8f8f8;

      img {
        width: 100%;
        border-radius: 8%;
      }
    }

    .cardtitle {
      font-weight: bold;
    }

    .cardcontent {
      position: absolute;
      left: 0;
      bottom: 0;
      /* edit the width to fit card */
      width: 100%;

      padding: 20px;
      background: #f2f2f2;
      border-top-left-radius: 20px;
      /* edit here to change the height of the content box */
      transform: translateY(150px);
      transition: transform 0.25s;
   
    }

    .cardcontent::before {
      content: "";
      position: absolute;
      top: -47px;
      right: -45px;
      width: 100px;
      height: 100px;
      transform: rotate(-175deg);
      border-radius: 50%;
      box-shadow: inset 48px 48px #f2f2f2;
    }

    .cardtitle {
      color: #131313;
      line-height: 15px;
    }

    .cardsubtitle {
      display: block;
      font-size: 12px;
      margin-bottom: 0px;
    }

    .carddescription {
      font-size: 14px;
      opacity: 0;
      transition: opacity 0.5s;
    }

    .card:hover .cardcontent {
      transform: translateY(0);
    }

    .card:hover .carddescription {
      opacity: 1;
      transition-delay: 0.25s;
    }
  }

}
