.wrapper {
  padding-top: 4rem;
  background-color: #000;

  html {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .background {
    background-color: #e8e8e8;
    padding: 25px 25px 25px 0;
    position: relative;
    width: 100%;
    .titlebox {
      top: 1%;
      position: absolute;
      display: flex;
      left: 26%;
      z-index: 2;
      margin-bottom: 6rem;
      h1 {
        font-weight: bold;
        font-size: 2rem;
        color: #fff;
      }
      @media (max-width: 768px) {
        text-align: center;
        left: 0%;
        padding: 0 40px;
      }
    }
  }

  .background::after {
    content: "";
    background: #000;

    height: 350px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  @media (min-width: 900px) {
    .background {
      padding: 0 0 25px;
    }
  }

  .container {
    margin: 0 auto;
    padding: 50px 0 0;
    max-width: 1400px;
    width: 100%;
    @media (max-width: 768px) {
    }
  }

  .panel {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 25px;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-top: 4rem;
    @media (max-width: 768px) {
      margin-top: 6rem;
      margin-left: 1rem;
    }
  }

  .pricing-table {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.08),
      0px 20px 31px 3px rgba(0, 0, 0, 0.09),
      0px 8px 20px 7px rgba(0, 0, 0, 0.02);
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 900px) {
    .pricing-table {
      flex-direction: row;
    }
  }

  .pricing-table * {
    text-align: center;
    text-transform: uppercase;
  }

  .pricing-plan {
    border-bottom: 1px solid #e1f1ff;
    padding: 25px;
  }

  .pricing-plan:last-child {
    border-bottom: none;
  }

  @media (min-width: 900px) {
    .pricing-plan {
      border-bottom: none;
      border-right: 1px solid #e1f1ff;
      flex-basis: 100%;
      padding: 25px 50px;
    }

    .pricing-plan:last-child {
      border-right: none;
    }
  }

  .pricing-img {
    margin-bottom: 25px;
    max-width: 100%;
  }

  .pricing-header {
    color: rgb(94, 156, 210);
    font-weight: 600;
    letter-spacing: 1px;
  }

  .pricing-features {
    color: rgb(50, 49, 49);
    font-weight: 600;
    letter-spacing: 1px;
    margin: 25px 0 5px;
    list-style: none;
  }

  .pricing-features-item {
    border-top: 5px solid #e8e8e8;
    font-size: 12px;
    line-height: 1.5;
    padding: 15px 0;
    text-align: left;
  }

  .pricing-features-item:last-child {
    border-bottom: 5px solid #e8e8e8;
  }

  .pricing-price {
    color: #0270cf;
    display: block;
    font-size: 32px;
    font-weight: 700;
  }

  .digitalmarketing {
    background-color: #000;
    @media (max-width:768px) {
      width: 100%;
      
    }

    .titlebox {
      display: flex;
      justify-content: center;
      text-align: center;
      color: #fff;
      font-size: 3rem;
      @media (max-width: 768px) {
        padding: 2rem 2rem;
        h1 {
          font-size: 2rem;
          font-weight: bold;
        }
      }
    }
    .cards {
      display: flex;
      gap: 40px;
      padding: 20px;
      justify-content: center;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
      }

      .card {
        width: 420px;
        display: flex;
        flex-direction: column;
        border-radius: 1.5rem;

        padding: 1.5rem;
        background-color: #fff;
        color: black;
        @media (max-width:768px) {
          width: 100%;
          
        }
      }
      .header {
        position: relative;
        margin: 0;
        margin-bottom: 2rem;
        overflow: hidden;
        border-radius: 0;
        border-bottom: 5px solid #000;
        background: transparent;
        padding-bottom: 1rem;
        text-align: center;
      }

      .title {
        display: block;
        font-family: sans-serif;
        font-size: 1.275rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        color: goldenrod;
        font-weight: bold;
      }

      .price-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        font-family: sans-serif;
        font-size: 3.5rem;
        line-height: 1;
      }

      .price-container span:first-child {
        margin-top: 10px;
        font-size: 3.25rem;
        line-height: 2.5rem;
        color: goldenrod;
      }

      .price-container span:nth-child(2) {
        align-self: flex-end;
        font-size: 2.25rem;
        line-height: 2.5rem;
        color: #000;
      }
      .price-container span:last-child {
        align-self: flex-end;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #888;
      }

      .lists {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        grid-row-gap: 0.75rem;
        row-gap: 0.75rem;
        font-size: 0.9175rem;
        line-height: 1.25rem;
        font-weight: bold;
      }

      .list {
        display: flex;
        .icon {
          color: green;
        }
      }

      .list span {
        margin-left: 0.51rem;

        .sub-lists {
          justify-content: center;

          .sub-list {
            display: flex;
          }
        }
      }
    }
  }
  .seo {
    background-color: #000;
    padding-bottom: 2rem;
    .seocontainer {
      display: flex;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        img {
          width: 100%;
        }
      }

      gap: 20px;
      .seoimg {
        padding: 20px;
        img {
          border-radius: 20px;
        }
      }

      .seoprice {
        padding-top: 20px;
        .titleBoxSeo {
          .pricetitle {
            @media (max-width: 768px) {
              padding: 0 1rem;
            }
            .cardseo {
              padding: 1rem;
              background-color: #fff;
              box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.05);
              max-width: 320px;
              border-radius: 20px;
              @media (max-width: 768px) {
                max-width: 100%;
                margin-bottom: 1.82rem;
                
              }
            }

            .titleseo {
              display: flex;
              align-items: center;
              @media (max-width:768px) {
                justify-content: center;
                
              }
       

              .onwards {
                color: #888;
                font-size: 18px;
              }
            }

            .titleseo .symb {
              font-weight: bold;
              color: white;
              text-align: center;
              position: relative;
              justify-content: center;
              align-items: center;
              font-size: 1.4rem;
              background-color: rgb(32, 210, 151);
              width: 2rem;
              height: 2rem;
              border-radius: 9999px;
            }

            .title-text-seo {
              margin-left: 0.5rem;
              color: #374151;
              font-size: 25px;
              padding-top: 12px;
              text-align: center;
            }

            .data {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
             
            }

            .data p {
              margin-top: 1rem;
              margin-bottom: 1rem;
              color: #1f2937;
              font-size: 2.25rem;
              line-height: 2.5rem;
              font-weight: 700;
              text-align: center;
            }
          }
        }

        .det {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          @media (max-width:768px) {
          flex-wrap: nowrap;
          flex-direction: column;
          padding: 0 1.5rem;
          gap: 20px;
            
          }

          .detcard {
            color: rgb(108, 107, 107);
            h3 {
              text-align: center;
              color: #080808;
            }

            padding: 1rem 0;
            background-color: #fff;
            box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
              0 4px 6px -2px rgba(0, 0, 0, 0.05);

            border-radius: 20px;
            margin-top: 80px;
            width: calc(50% - 10px);
            @media (max-width:768px) {
              width: 100%;
              margin-top: 0%;
              
            }
            li {
              list-style: none;
            }
          }
        }
      }
    }
    .seoinfo {
      margin-left: 20px;
      padding: 1rem 0;
      background-color: #fff;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);

      border-radius: 20px;
      @media (max-width:768px) {
        margin: 20px 1.5rem 0 1.5rem;
    
        
      }
      .title {
        text-align: center;
        font-size: 23.8px;
        font-weight: bold;
      }
      .detcols {
        display: flex;
        gap: 0px;
        color: rgb(108, 107, 107);
        @media (max-width:768px) {
          flex-direction: column;
       
          
        }
        .detcol {
          border-right: 5px solid #e3ecf6;
          padding: 0 0.61rem;

          ul {
            li {
              list-style: none;
            }
          }
        }
        &:nth-child(4) {
          border-right: none;
        }
      }
      #detcol4 {
        border: none;
      }
    }
  }
}

.ics {
  color: green;
}
