.Wrapper {
  padding: 20px 120px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  .title {
    span {
      text-transform: uppercase;
      letter-spacing: 1.5px;
      font-size: 1rem;
      color: #717171;
    }
    h1 {
      text-transform: capitalize;
      letter-spacing: 0.8px;
      font-family: "Roboto", sans-serif;
      font-weight: 900;
      font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
      background-color: #000000;
      background: linear-gradient(-45deg, grey 0%, black 100%);
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      @media (max-width: 768px) {
        font-size: clamp(1.8375rem, 1.25rem + 0.35vw, 2rem);
      }
    }
    hr {
      display: block;
      background: #000000;
      height: 0.25rem;
      width: 6.25rem;
      border: none;
      margin: 1.125rem 0 1.875rem 0;
    }
  }

  .content {
    .box {
      display: flex;
      width: 100%;
      height: 100%;
      gap: 50px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        display: block;
        position: relative;
    
        &:nth-child(2) {
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
        }
      }
      .img {
        width: 50%;
        @media (max-width: 768px) {
          width: 100%;
        }
        img {
          width: 100%;
    
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        border-radius: 5%;
        padding: 10px 20px;
        button{
          align-items: start;
          justify-content: start;
          align-self: flex-start;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        &:hover {
          background-color: #e8e8e8;
        }
      }
    }
  }
}

//button
.cssbuttonsiobutton {
  background: black;
  color: white;
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-size: 17px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 1.6em -0.6em black;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  padding-right: 3.3em;
  align-self: flex-start;
  margin-top: 2rem;
}

.cssbuttonsiobutton .icon {
  background: white;
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2em;
  width: 2.2em;
  border-radius: 0.7em;
  box-shadow: 0.1em 0.1em 0.6em 0.2em black;
  right: 0.3em;
  transition: all 0.3s;
}

.cssbuttonsiobutton:hover .icon {
  width: calc(100% - 0.6em);
}

.cssbuttonsiobutton .icon svg {
  width: 1.1em;
  transition: transform 0.3s;
  color: black;
}

.cssbuttonsiobutton:hover .icon svg {
  transform: translateX(0.1em);
}

.cssbuttonsiobutton:active .icon {
  transform: scale(0.95);
}
